import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { graphql, Link } from "gatsby";
import SiteMetadata from "../../components/SiteMetaData";
import { FaAngleLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GatsbyImage } from "gatsby-plugin-image";
import { Select } from "@chakra-ui/react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { checkRole } from "../../components/Utils";

const SuchePage = ({ data, location }) => {
  const { user } = useAuth0();
  const state = location.state;
  const givenAuthor = state?.author;
  const givenCategory = state?.category;
  const givenYear = state?.year;
  const givenMonth = state?.month;
  const givenKeyword = state?.keyword;
  const givenSerie = state?.serie;

  const existingCategories = data.allContentfulCategory.nodes
    .sort((a, b) => a.order - b.order)
    .map((item) => item.title);
  const publishedSeries = [
    ...new Set(
      data.allContentfulPost.nodes
        .filter((item) => item.draft !== true)
        .map((item) => item.serie?.title)
    ),
  ].filter(Boolean);

  const [filteredCategory, setFilteredCategory] = useState(
    givenCategory ? givenCategory : ""
  );

  const allSeries = data.allContentfulSerie.nodes.filter((item) =>
    publishedSeries.includes(item.title)
  );

  const filteredCards = allSeries.filter((item) =>
    filteredCategory ? item.category.title === filteredCategory : true
  );

  const handleChangeCategory = (event) => {
    event.preventDefault();
    setFilteredCategory(event.target.value);
  };
  const [visibility, setVisibility] = useState(true);

  const Sidebar = () => {
    return (
      <div className="md:w-1/4 md:pl-2.25 order-2 pb-5 md:pb-0">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3">
          <div>
            <div className="flex justify-between items-center">
              <div className="items-center w-full">
                <button
                  className="flex text-xl items-center flex-1"
                  onClick={() => setVisibility(!visibility)}
                >
                  {visibility ? (
                    <FaChevronDown size={14} className="mr-1 md:hidden" />
                  ) : (
                    <FaChevronUp size={14} className="mr-1 md:hidden" />
                  )}
                  <div className="uppercase md:hidden">filter</div>
                  <div className="hidden lg:contents">Reihen</div>
                </button>
              </div>
              <Link
                to="../"
                state={{
                  author: givenAuthor,
                  category: givenCategory,
                  month: givenMonth,
                  year: givenYear,
                  keyword: givenKeyword,
                  serie: givenSerie,
                }}
              >
                <div className="flex items-center text-blue-600">
                  <FaAngleLeft />
                  {state.link ? (
                    <div>Zurück</div>
                  ) : (
                    <div className="whitespace-nowrap">Alle Beiträge</div>
                  )}
                </div>
              </Link>
            </div>
          </div>
          {visibility && (
            <div className="mt-5">
              <div className="">Kategorie</div>
              <div className="">
                <Select
                  placeholder="Alle"
                  value={filteredCategory}
                  onChange={handleChangeCategory}
                >
                  {existingCategories.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const Cards = () => {
    return (
      <div className="md:w-3/4 md:pr-0.75 order-1 relative">
        {filteredCards.length > 0 ? (
          <div className="grid grid-cols-3 gap-3">
            {filteredCards.map((item, index) => (
              <Link
                to={`/beitraege`}
                key={index}
                state={{
                  serie: item.title,
                  author: givenAuthor,
                  month: givenMonth,
                  year: givenYear,
                  keyword: givenKeyword,
                }}
                className="bg-yellow-500 dark:bg-gray-600 rounded-lg overflow-hidden transform shadow-sm"
              >
                <GatsbyImage
                  image={item.gallery.gatsbyImageData}
                  alt={item.title}
                />
                <div className="p-2">
                  <div className="font-medium">{item.title}</div>
                </div>
              </Link>
            ))}{" "}
          </div>
        ) : (
          <div className="absolute h-full w-full">
            <div className="text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              Keine Treffer
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout>
      <SiteMetadata title="Beiträge" description="Die Beiträge im Überblick" />
      {checkRole(user) && (
        <div className="bg-gray-50 dark:bg-gray-900 pb-6 flex-grow dark:text-white">
          <div className="container">
            <div className="text-center text-4xl py-6 lg:hidden">Reihen</div>
            <div className="md:flex">
              <Sidebar />
              <Cards />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withAuthenticationRequired(SuchePage);

export const query = graphql`
  query SucheQuery {
    allContentfulCategory {
      nodes {
        title
      }
    }
    allContentfulSerie {
      nodes {
        title
        category {
          title
        }
      }
    }
    allContentfulPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        slug
        author
        tags
        draft
        serie {
          title
        }
        publishedAt(formatString: "DD-MM-YYYY")
        category {
          title
        }
        gallery {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 1000
            aspectRatio: 1.77
          )
        }
      }
    }
    allContentfulCategory {
      nodes {
        title
        order
      }
    }
    allContentfulSerie {
      nodes {
        title
        gallery {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 1000
            aspectRatio: 1.77
          )
        }
      }
    }
  }
`;
